import Auth from './Auth'
import Data from './Data'
import Options from './Options'
import Manga from './Manga'

export default {
  Auth,
  Data,
  Options,
  Manga
}
